import {get, round} from 'lodash'
export default function applyDiscount(menu) {
  try {

    if (["cravestudiocity", 'cravesunset'].indexOf(menu.shopId) !== -1) {


      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          if (false) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                const productName = get(product, 'name.en', '').toLowerCase()
                const containsChicken = productName.indexOf('chicken') !== -1
                const containsVegan = productName.indexOf('vegan') !== -1
                if (containsChicken && !containsVegan) {
                  const originalDescription = get(product, 'description.en', '')
                  return Object.assign({}, product, {
                    // originalPrice: product.price,
                    price: round(Number(product.price) + 3, 2),
                    // description: {en: "⚠️$3 temporary surcharge is included in chicken dishes due to raising cost. Thanks for your understanding⚠️" + originalDescription}
                  })
                } else {
                  return product
                }
              })
            })
          }
          return subMenu
        })
      })
    }
    // if (["incanorthhills", 'incamaya'].indexOf(menu.shopId) !== -1) {
    //   return Object.assign({}, menu, {
    //     subMenus: menu.subMenus.map((subMenu) => {
    //       if (true) {
    //         return Object.assign({}, subMenu, {
    //           // apply discount
    //           products: subMenu.products.map((product) => {
    //             if (product.name && (product.name.en.toLowerCase().indexOf('saltado') != -1)) {
    //               return Object.assign({}, product, {
    //                 originalPrice: product.price,
    //                 price: Math.floor(product.price * 0.5 * 100) / 100
    //               })
    //             }
    //             return product
    //           })
    //         })
    //       }
    //       return subMenu
    //     })
    //   })
    // }
    if (["themelt", "muiishi"].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          if (subMenu.tag === '50off') {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.5 * 100) / 100
                })
              })
            })
          }
          return subMenu
        })
      })
    }

    if (["pablitostacosnorth"].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          // check if it is feb 1, 2024
          const today = new Date()
          
          const isPromoDate = (today.getMonth() === 6) && (today.getDate() === 16) && (today.getFullYear() === 2024)
          if (isPromoDate) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.8 * 100) / 100,
                })
              })
            })
          }
          return subMenu
        })
      })
    }

    if (["pablitostacosnoho"].indexOf(menu.shopId) !== -1) {
      const today = new Date()
      const isPromoDate = (today.getMonth() === 6) && (today.getDate() === 16) && (today.getFullYear() === 2024)
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          if (subMenu.tag && subMenu.tag.indexOf('20off') !== -1) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.8 * 100) / 100,
                })
              })
            })
          }
          if (subMenu.tag && subMenu.tag.indexOf('-30off') !== -1) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.7 * 100) / 100,
                })
              })
            })
          }
        
      
          if (isPromoDate) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                let modgroups = product.modgroups || []
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.8 * 100) / 100,
                  modgroups: modgroups.map((modgroup) => {
                    if (!modgroup.modifiers) { return modgroup }
                    return Object.assign({}, modgroup, {
                      modifiers: modgroup.modifiers.filter((modifier) => {
                        return (modifier.name && (modifier.name.indexOf('Fried Oyster') === -1)  && (modifier.name.indexOf('NO GREEN SAUCE') === -1) && (modifier.name.indexOf('Green Birria') === -1))
                      })
                    })
                  })
                })
              })
            })
          }
          if (true) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                let modgroups = product.modgroups || []
                return Object.assign({}, product, {
                  // originalPrice: product.price,
                  // price: Math.floor(product.price * 0.8 * 100) / 100,
                  modgroups: modgroups.map((modgroup) => {
                    if (!modgroup.modifiers) { return modgroup }
                    return Object.assign({}, modgroup, {
                      modifiers: modgroup.modifiers.filter((modifier) => {
                        return (modifier.name && (modifier.name.indexOf('Fried Oyster') === -1)  && (modifier.name.indexOf('NO GREEN SAUCE') === -1) && (modifier.name.indexOf('Green Birria') === -1))
                      })
                    })
                  })
                })
              })
            })
          }
          return subMenu
        })
      })
    }
    // if (["kokorollburbank"].indexOf(menu.shopId) !== -1) {
    //   return Object.assign({}, menu, {
    //     subMenus: menu.subMenus.map((subMenu) => {
    //       if (true) {
    //         return Object.assign({}, subMenu, {
    //           // apply discount
    //           products: subMenu.products.map((product) => {
    //             let modgroups = product.modgroups || []
    //             return Object.assign({}, product, {
    //               // originalPrice: product.price,
    //               // price: Math.floor(product.price * 0.8 * 100) / 100,
    //               modgroups: modgroups.map((modgroup) => {
    //                 if (!modgroup.modifiers) { return modgroup }
    //                 return Object.assign({}, modgroup, {
    //                   modifiers: modgroup.modifiers.filter((modifier) => {
    //                     return (modifier.name && (modifier.name.indexOf('Beef Bulgogi') === -1) && (modifier.name.indexOf('Shrimp') === -1) && (modifier.name.indexOf('Boba') === -1))
    //                   })
    //                 })
    //               })
    //             })
    //           })
    //         })
    //       }
    //       return subMenu
    //     })
    //   })
    // }

    if (["domenicos"].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          if (true) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                let modgroups = product.modgroups || []
                return Object.assign({}, product, {
                  // originalPrice: product.price,
                  // price: Math.floor(product.price * 0.9 * 100) / 100,
                  modgroups: modgroups.map((modgroup) => {
                    if (!modgroup.modifiers) { return modgroup }
                    return Object.assign({}, modgroup, {
                      modifiers: modgroup.modifiers.filter((modifier) => {
                        return (modifier.name && (modifier.name.indexOf('Sierra Mist (2L)') === -1)
                        //  && (modifier.name.indexOf('Anchovies') === -1)
                        //  && (modifier.name.indexOf('Prosciutto') === -1)
                        //  && (modifier.name.indexOf('28') === -1)
                        //  && (modifier.name.indexOf('Goat Cheese') === -1)
                        )
                      })
                    })
                  })
                })
              })
            })
          }
          return subMenu
        })
      })
    }

    if (["tokyoya"].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          if (true) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                let modgroups = product.modgroups || []
                return Object.assign({}, product, {
                  // originalPrice: product.price,
                  // price: Math.floor(product.price * 0.9 * 100) / 100,
                  modgroups: modgroups.map((modgroup) => {
                    if (!modgroup.modifiers) { return modgroup }
                    return Object.assign({}, modgroup, {
                      modifiers: modgroup.modifiers.filter((modifier) => {
                        return (modifier.name && (modifier.name.indexOf('Beef Gyoza') === -1)
                        //  && (modifier.name.indexOf('Anchovies') === -1)
                        //  && (modifier.name.indexOf('Prosciutto') === -1)
                        //  && (modifier.name.indexOf('28') === -1)
                        //  && (modifier.name.indexOf('Goat Cheese') === -1)
                        )
                      })
                    })
                  })
                })
              })
            })
          }
          return subMenu
        })
      })
    }
    

    if (["-beachwood"].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          if (true) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                let modgroups = product.modgroups || []
                return Object.assign({}, product, {
                  // originalPrice: product.price,
                  // price: Math.floor(product.price * 0.85 * 100) / 100,
                  modgroups: modgroups.map((modgroup) => {
                    if (!modgroup.modifiers) { return modgroup }
                    return Object.assign({}, modgroup, {
                      modifiers: modgroup.modifiers.filter((modifier) => {
                        return (modifier.name && (modifier.name.indexOf('12in Gluten-Free Crust') === -1)
                        )
                      })
                    })
                  })
                })
              })
            })
          }
          return subMenu
        })
      })
    }
    if (["beachwood"].indexOf(menu.shopId) !== -1) {
      // check if it is saturday or sunday
      const today = new Date()
      const day = today.getDay()
      const isWeekend = (day === 0) || (day === 6)
      const isMorning = (today.getHours() < 16)

      if (isWeekend && isMorning) {  
        return Object.assign({}, menu, {
          subMenus: menu.subMenus.map((subMenu) => {
            if (true) {
              return Object.assign({}, subMenu, {
                // apply discount
                products: subMenu.products.map((product) => {
                  let modgroups = product.modgroups || []
                  return Object.assign({}, product, {
                    // originalPrice: product.price,
                    // price: Math.floor(product.price * 0.8 * 100) / 100,
                    modgroups: modgroups.map((modgroup) => {
                      if (!modgroup.modifiers) { return modgroup }
                      return Object.assign({}, modgroup, {
                        modifiers: modgroup.modifiers.map((modifier) => {
                          if (modifier.name.indexOf('16in') !== -1) {
                            console.log('!!!',modifier)
                            return {
                              id: "16inch-out",
                              name: "(unavailable) 16in *not on weekends before 4pm",
                              price: 0,
                              
                            }
                          }
                          else {
                            return modifier
                          }
                          // return ((modifier.name.indexOf('16in') === -1))
                        })
                      })
                    })
                  })
                })
              })
            }
            return subMenu
          })
        })
      }

      return menu
    }
    if (["omars"].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          if (true) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                let modgroups = product.modgroups || []
                return Object.assign({}, product, {
                 // originalPrice: product.price,
                  // price: Math.floor(product.price * 0.8 * 100) / 100,
                  modgroups: modgroups.map((modgroup) => {
                    if (!modgroup.modifiers) { return modgroup }
                    return Object.assign({}, modgroup, {
                      modifiers: modgroup.modifiers.filter((modifier) => {
                        return (modifier.name && (modifier.name.indexOf('Vegan Cheese') === -1))
                      })
                    })
                  })
                })
              })
            })
          }
          return subMenu
        })
      })
    }

    if (["sushidon-"].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          if (true) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                let modgroups = product.modgroups || []
                return Object.assign({}, product, {
                  // originalPrice: product.price,
                  // price: Math.floor(product.price * 0.8 * 100) / 100,
                  modgroups: modgroups.map((modgroup) => {
                    if (!modgroup.modifiers) { return modgroup }
                    return Object.assign({}, modgroup, {
                      modifiers: modgroup.modifiers.filter((modifier) => {
                        return (modifier.name && (modifier.name.indexOf('Brown Rice') === -1) && (modifier.name.indexOf('Crunchy Tempura Flakes') === -1))
                      })
                    })
                  })
                })
              })
            })
          }
          return subMenu
        })
      })
    }
    

    if (["kokorolltorrance"].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          if (true) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                let modgroups = product.modgroups || []
                return Object.assign({}, product, {
                  // originalPrice: product.price,
                  // price: Math.floor(product.price * 0.8 * 100) / 100,
                  modgroups: modgroups.map((modgroup) => {
                    if (!modgroup.modifiers) { return modgroup }
                    return Object.assign({}, modgroup, {
                      modifiers: modgroup.modifiers.filter((modifier) => {
                        return (modifier.name && (modifier.name.indexOf('Cheddar') === -1) && (modifier.name.indexOf('Dragon') === -1))
                      })
                    })
                  })
                })
              })
            })
          }
          return subMenu
        })
      })
    }
    
    if ((menu.shopId.indexOf('kluckin') !== -1) && (menu.shopId.indexOf('kluckinoaks') == -1)) {
   
      console.log('kluckin!')
      menu.subMenus.unshift({"draft":false,"name":{"en":"Special"},"orderIndex":[],"type":"subMenu","shopId":"kluckin","id":"QA6vknKrHm5eDdXW8DpC","products":[{"id":"SFHLzgdXdnEhbv16UC3m","name":{"en":"Kluckin' Ten"},"price":"10","description":{"en":"1 Tender, Kluckin' Mac, Fries."},"modgroups":[{"modifiers":[{"id":"m12","price":null,"name":"No Heat­"},{"name":"Kluckin Mild","id":"m22","price":null},{"id":"m32","name":"Kluckin Hot","price":null},{"name":"Kluckin Fire!","price":null,"id":"m42"}],"id":"we2","name":"How Kluckin Spicy do you want it ?","required":true,"type":"single"},{"type":"buffet","modifiers":[{"name":"Kluckin Sauce","id":"sauce","price":0.5},{"id":"pickles","name":"Extra Pickles","price":0.5}],"id":"extra","name":"Add Ons"}],"photo":{"h":1184,"e":"png","w":1076,"s":"the-kluckin-chicken","id":"kluckin-ten","type":"g"},"sub":"Special","tags":","}]})
      // return Object.assign({}, menu, {
      //   subMenus: 
      // })
    }

   

    if (["pablitostacosburbank"].indexOf(menu.shopId) !== -1) {
      let burritoSpecial = false  
      let burritoSpecialLoc = false
      const today = new Date()
      const isPromoDate = (today.getMonth() === 6) && (today.getDate() === 16) && (today.getFullYear() === 2024)
      const subMenus = menu.subMenus.map((subMenu, index) => {
          
        if (subMenu.tag === 'family-packs') {
          return Object.assign({}, subMenu, {
            // apply discount
            products: subMenu.products.map((product) => {
              let modgroups = product.modgroups || []
              return Object.assign({}, product, {
                originalPrice: product.price,
                price: Math.floor(product.price * 0.9 * 100) / 100,
                modgroups: modgroups.map((modgroup) => {
                  if (!modgroup.modifiers) { return modgroup }
                  return Object.assign({}, modgroup, {
                    modifiers: modgroup.modifiers.map((modifier) => {
                      return Object.assign({}, modifier, {
                        price: Math.floor(modifier.price * 0.9 * 100) / 100,
                      })
                    })
                  })
                })
              })
            })
          })
        }
        if (subMenu.tag && subMenu.tag.indexOf('10-burrito') !== -1) {
          
          const specialProducts = [] 
          subMenu.products.forEach((product) => {
            const newProduct = Object.assign({}, product, {
              name: {en: `9" ${product.name.en}`},
              // originalPrice: product.price,
              price: Math.min(product.price, 10),
            })
            if (newProduct.price > 9) {
              specialProducts.push(newProduct)
            }
          })
          burritoSpecial = Object.assign({}, subMenu, {
            // apply discount
            name: {en: `9" Burrito Special`},
            description: {en: `9" flour tortilla special.`},
            products: specialProducts
            })
          console.log('burrito', burritoSpecial)
          burritoSpecialLoc = 2
          return subMenu
        }
        
        if (subMenu.tag === '25off') {
          return Object.assign({}, subMenu, {
            // apply discount
            products: subMenu.products.map((product) => {
              let modgroups = product.modgroups || []
              return Object.assign({}, product, {
                originalPrice: product.price,
                price: Math.floor(product.price * 0.75 * 100) / 100,
                modgroups: modgroups.map((modgroup) => {
                  if (!modgroup.modifiers) { return modgroup }
                  return Object.assign({}, modgroup, {
                    modifiers: modgroup.modifiers.map((modifier) => {
                      return Object.assign({}, modifier, {
                        price: Math.floor(modifier.price * 0.75 * 100) / 100,
                      })
                    })
                  })
                })
              })
            })
          })
        }
        if (subMenu.tag === '20off') {
          console.log('😱 detected 20offf')
          return Object.assign({}, subMenu, {
            // apply discount
            products: subMenu.products.map((product) => {
              let modgroups = product.modgroups || []
              return Object.assign({}, product, {
                originalPrice: product.price,
                price: Math.floor(product.price * 0.8 * 100) / 100,
                modgroups: modgroups.map((modgroup) => {
                  if (!modgroup.modifiers) { return modgroup }
                  return Object.assign({}, modgroup, {
                    modifiers: modgroup.modifiers.map((modifier) => {
                      return Object.assign({}, modifier, {
                        price: Math.floor(modifier.price * 0.8 * 100) / 100,
                      })
                    })
                  })
                })
              })
            })
          })
        }



        if (subMenu.tag && subMenu.tag.indexOf('50off') !== -1) {
          // console.log('detected 50off')
          return Object.assign({}, subMenu, {
            // apply discount
            products: subMenu.products.map((product) => {
              let modgroups = product.modgroups || []
              return Object.assign({}, product, {
                originalPrice: product.price,
                price: Math.floor(product.price * 0.5 * 100) / 100,
              })
            })
          })
        }
        if (subMenu.tag && subMenu.tag.indexOf('20off') !== -1) {
          // console.log('detected 50off')
          return Object.assign({}, subMenu, {
            // apply discount
            products: subMenu.products.map((product) => {
              let modgroups = product.modgroups || []
              return Object.assign({}, product, {
                originalPrice: product.price,
                price: Math.floor(product.price * 0.8 * 100) / 100,
              })
            })
          })
        }
        if (subMenu.tag && subMenu.tag.indexOf('hamburguetza') !== -1) {
          // console.log('detected 50off')
          return Object.assign({}, subMenu, {
            // apply discount
            products: subMenu.products.map((product) => {
              let modgroups = product.modgroups || []
              return Object.assign({}, product, {
                originalPrice: product.price,
                price: Math.floor(product.price * 0.7 * 100) / 100,
              })
            })
          })
        }
        if (subMenu.tag && subMenu.tag.indexOf('taquitos') !== -1) {
          // console.log('detected 50off')
          return Object.assign({}, subMenu, {
            // apply discount
            products: subMenu.products.map((product) => {
              let modgroups = product.modgroups || []
              return Object.assign({}, product, {
                // originalPrice: product.price,
                // price: Math.floor(product.price * 0.8 * 100) / 100,
              })
            })
          })
        }

        if (subMenu.tag && subMenu.tag.indexOf('tuesday-only') !== -1) {
          
          return Object.assign({}, subMenu, {
            // apply discount
            products: subMenu.products.map((product) => {
              let modgroups = product.modgroups || []

  
              return Object.assign({}, product, {
                // originalPrice: product.price,
                // price: Math.floor(product.price * 0.80 * 100) / 100,
                modgroups: modgroups.map((modgroup) => {
                  if (!modgroup.modifiers) { return modgroup }
                  return Object.assign({}, modgroup, {
                    modifiers: modgroup.modifiers.filter((modifier) => {
                      console.log(modifier.name)
                      return (modifier.name && (String(modifier.name).indexOf('Fried Oyster') === -1)
                      // && (modifier.name.indexOf('POLLO') === -1)
                      // && (modifier.name.indexOf('Chicken Mole') === -1)
                      )
                    })
                  })
                })
              })
            })
          })
        }

        if (isPromoDate) {
          return Object.assign({}, subMenu, {
            // apply discount
            products: subMenu.products.map((product) => {
              let modgroups = product.modgroups || []

  
              return Object.assign({}, product, {
                originalPrice: product.price,
                price: Math.floor(product.price * 0.80 * 100) / 100,
                modgroups: modgroups.map((modgroup) => {
                  if (!modgroup.modifiers) { return modgroup }
                  return Object.assign({}, modgroup, {
                    modifiers: modgroup.modifiers.filter((modifier) => {
                      console.log(modifier.name)
                      return (modifier.name && (String(modifier.name).indexOf('Fried Oyster') === -1)
                      // && (modifier.name.indexOf('POLLO') === -1)
                      // && (modifier.name.indexOf('Chicken Mole') === -1)
                      )
                    })
                  })
                })
              })
            })
          })
        }

        

        return Object.assign({}, subMenu, {
          // apply discount
          products: subMenu.products.map((product) => {
            let modgroups = product.modgroups || []

            // console.log('p tag', product.tags)
            if (product.tags && (product.tags.indexOf('50off--') !== -1)) {
              return Object.assign({}, product, {
                originalPrice: product.price,
                price: Math.floor(product.price * 0.5 * 100) / 100,
              })
            }

            return Object.assign({}, product, {
              // originalPrice: product.price,
              // price: Math.floor(product.price * 0.80 * 100) / 100,
              modgroups: modgroups.map((modgroup) => {
                if (!modgroup.modifiers) { return modgroup }
                return Object.assign({}, modgroup, {
                  modifiers: modgroup.modifiers.filter((modifier) => {
                    console.log(modifier.name)
                    return (modifier.name && (String(modifier.name).indexOf('Fried Oyster') === -1)
                    // && (modifier.name.indexOf('POLLO') === -1)
                    // && (modifier.name.indexOf('Chicken Mole') === -1)
                    )
                  })
                })
              })
            })
          })
        })

        

   
        return subMenu
      })
      if (burritoSpecial) {
        // console.log('a', subMenus.splice(2, 0, burritoSpecial))
        const newSubMenus = subMenus.slice()
        newSubMenus.splice(burritoSpecialLoc + 1, 0, burritoSpecial)
        return Object.assign({}, menu, {
          subMenus: newSubMenus
        })
      }
      return Object.assign({}, menu, {
        subMenus
      })
    }


    if (["pablitosworld"].indexOf(menu.shopId) !== -1) {
      
      
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          
          if (subMenu.tag === 'family-packs') {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                let modgroups = product.modgroups || []
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.9 * 100) / 100,
                  modgroups: modgroups.map((modgroup) => {
                    if (!modgroup.modifiers) { return modgroup }
                    return Object.assign({}, modgroup, {
                      modifiers: modgroup.modifiers.map((modifier) => {
                        return Object.assign({}, modifier, {
                          price: Math.floor(modifier.price * 0.9 * 100) / 100,
                        })
                      })
                    })
                  })
                })
              })
            })
          }
 
 
          if (subMenu.tag && subMenu.tag.indexOf('25off') !== -1) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                let modgroups = product.modgroups || []
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.75 * 100) / 100,
                  modgroups: modgroups.map((modgroup) => {
                    if (!modgroup.modifiers) { return modgroup }
                    return Object.assign({}, modgroup, {
                      modifiers: modgroup.modifiers.map((modifier) => {
                        return Object.assign({}, modifier, {
                          price: Math.floor(modifier.price * 0.75 * 100) / 100,
                        })
                      })
                    })
                  })
                })
              })
            })
          }



          if (subMenu.tag && subMenu.tag.indexOf('50off') !== -1) {
            // console.log('detected 50off')
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                let modgroups = product.modgroups || []
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.5 * 100) / 100,
                })
              })
            })
          }
          if (subMenu.tag && subMenu.tag.indexOf('20off') !== -1) {
            // console.log('detected 50off')
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                let modgroups = product.modgroups || []
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.8 * 100) / 100,
                })
              })
            })
          }

          if (subMenu.tag && subMenu.tag.indexOf('add-impossible-burger') !== -1) {
            // console.log('detected 50off')
            return subMenu
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                let modgroups = product.modgroups || []
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.75 * 100) / 100,
                })
              })
            })
          }

          

          return Object.assign({}, subMenu, {
            // apply discount
            products: subMenu.products.map((product) => {
              let modgroups = product.modgroups || []
              return Object.assign({}, product, {
                // originalPrice: product.price,
                // price: Math.floor(product.price * 0.8 * 100) / 100,
                modgroups: modgroups.map((modgroup) => {
                  if (!modgroup.modifiers) { return modgroup }
                  return Object.assign({}, modgroup, {
                    modifiers: modgroup.modifiers.filter((modifier) => {
                      return (modifier.name && (modifier.name.indexOf('Fried Oyster') === -1)
                      // && (modifier.name.indexOf('POLLO') === -1)
                      // && (modifier.name.indexOf('Chicken Mole') === -1)
                      )
                    })
                  })
                })
              })
            })
          })

          

     
          return subMenu
        })
      })
    }

    
    if (["frontierwok-", 'sushidon-', 'muiishi-'].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          if (true) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.9 * 100) / 100,
                  
                })
              })
            })
          }
          return subMenu
        })
      })
    }
    if (["kokorolltorrance"].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          if (true) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                if (!product.modgroups) {return product}
                return Object.assign({}, product, {
                  modgroups: product.modgroups.map((modgroup) => {
                    if (!modgroup.modifiers) { return modgroup }
                    return Object.assign({}, modgroup, {
                      modifiers: modgroup.modifiers.filter((modifier) => {
                        return (modifier.name && (modifier.name.indexOf('Dragon Fruit') === -1))
                      })
                    })
                  })
                })
              })
            })
          }
          return subMenu
        })
      })
    }
    if (["kokorolllacanada"].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          if (true) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                if (!product.modgroups) {return product}
                return Object.assign({}, product, {
                  modgroups: product.modgroups.map((modgroup) => {
                    if (!modgroup.modifiers) { return modgroup }
                    return Object.assign({}, modgroup, {
                      modifiers: modgroup.modifiers.filter((modifier) => {
                        return (modifier.name && (modifier.name.indexOf('Mango Jelly') === -1) 
                        // && (modifier.name.indexOf('Shrimp Tempura') === -1) 
                        && (modifier.name.indexOf('Strawberry') === -1) 
                        && (modifier.name.indexOf('Guava') === -1) 
                        )
                      })
                    })
                  })
                })
              })
            })
          }
          return subMenu
        })
      })
    }

    if (["coralcafe"].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          let tag = subMenu.tag || ''
          if (tag.indexOf('no-increase') === -1) {
            return Object.assign({}, subMenu, {
              products: subMenu.products.map((product) => {
                let modgroups = product.modgroups || []
                return Object.assign({}, product, {
                  price: Number(product.price) + 1,
                  modgroups: modgroups.map((modgroup) => {
                    if (!modgroup.modifiers) { return modgroup }
                    return Object.assign({}, modgroup, {
                      modifiers: modgroup.modifiers.filter((modifier) => {
                        return (modifier.name && (modifier.name.indexOf('Planks') === -1))
                      })
                    })
                  })
                })
              })
            })
          }
          return subMenu
        })
      })
    }

    // if (["lancers"].indexOf(menu.shopId) !== -1) {
    //   return Object.assign({}, menu, {
    //     subMenus: menu.subMenus.map((subMenu) => {
    //       let tag = subMenu.tag || ''
    //       if (tag.indexOf('no-increase') === -1) {
    //         return Object.assign({}, subMenu, {
    //           products: subMenu.products.map((product) => {
    //             if (!Number(product.price)) {
    //               return product
    //             }
    //             return Object.assign({}, product, {
    //               price: Math.floor((Number(product.price) +1) * 100) / 100
    //             })
    //           })
    //         })
    //       }
    //       return subMenu
    //     })
    //   })
    // }

    if (["gourmet88"].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          let tag = subMenu.tag || ''
          if (tag.indexOf('no-increase') === -1) {
            return Object.assign({}, subMenu, {
              products: subMenu.products.map((product) => {
                if (!Number(product.price)) {
                  return product
                }
                return Object.assign({}, product, {
                  price: ((Number(product.price) + 1))
                })
              })
            })
          }
          return subMenu
        })
      })
    }
    
  if (["unclereds"].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          if (true) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                if (!product.modgroups) {return product}
                return Object.assign({}, product, {
                  modgroups: product.modgroups.map((modgroup) => {
                    if (!modgroup.modifiers) { return modgroup }
                    return Object.assign({}, modgroup, {
                      modifiers: modgroup.modifiers.filter((modifier) => {
                        return (modifier.name && (modifier.name.indexOf('Spinach') === -1))
                      })
                    })
                  })
                })
              })
            })
          }
          return subMenu
        })
      })
    }
    // if (["pablitostacosburbank"].indexOf(menu.shopId) !== -1) {
    //   return Object.assign({}, menu, {
    //     subMenus: menu.subMenus.map((subMenu) => {
    //       if (true) {
    //         return Object.assign({}, subMenu, {
    //           // apply discount
    //           products: subMenu.products.filter((product) => {
    //             let {name, description} = product
    //             name = (name && name.en) || ''
    //             description = (description && description.en) || ''
    //             const noSausageName = (name.toLowerCase().indexOf('chicharron') === -1)
    //             const noSausageDesc = description.toLowerCase().indexOf('chicharron') === -1
    //             return noSausageName && noSausageDesc
    //           })
    //         })
    //       }
    //       return subMenu
    //     })
    //   })
    // }


    if (["newdeal", 'wildcarvery'].indexOf(menu.shopId) !== -1) {
      return Object.assign({}, menu, {
        subMenus: menu.subMenus.map((subMenu) => {
          if (subMenu.tag && (subMenu.tag.indexOf('discount-50-off') !== -1)) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.5 * 100) / 100
                })
              })
            })
          }
          if (subMenu.tag && (subMenu.tag.indexOf('discount-30-off') !== -1)) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                return Object.assign({}, product, {
                  originalPrice: product.price,
                  price: Math.floor(product.price * 0.7 * 100) / 100
                })
              })
            })
          }
          if (subMenu.tag && (subMenu.tag.indexOf('wine') !== -1)) {
            return Object.assign({}, subMenu, {
              // apply discount
              products: subMenu.products.map((product) => {
                return Object.assign({}, product, {
                  // originalPrice: product.price,
                  // price: Math.floor(product.price * 0.8 * 100) / 100
                })
              })
            })
          }
          // if (subMenu.tag && (subMenu.tag.indexOf('spirits') !== -1)) {
          //   return Object.assign({}, subMenu, {
          //     // apply discount
          //     products: subMenu.products.map((product) => {
          //       return Object.assign({}, product, {
          //         originalPrice: product.price,
          //         price: Math.floor(product.price * 0.75 * 100) / 100
          //       })
          //     })
          //   })
          // }
          return subMenu
        })
      })
    }


    return menu
  } catch (e) {
    console.log(e)
    return menu
  }

}
